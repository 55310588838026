'use client';

import { Icon } from '@/components/utilities';
import { useState, useEffect, useRef } from 'react';

const options = [
  { text: 'video streaming', icon: 'video', className: 'text-brand-blue' },
  { text: 'computer vision', icon: 'objects', className: 'text-brand-green' },
  { text: 'image uploads', icon: 'image', className: 'text-brand-yellow' },
  { text: 'face detection', icon: 'faces', className: 'text-brand-magenta' },
  { text: 'voice notes', icon: 'microphone', className: 'text-brand-pink' },
  { text: 'transcripts', icon: 'track', className: 'text-brand-orange' },
  { text: 'nsfw filtering', icon: 'nsfw', className: 'text-brand-red' },
];

export default function AnimatedText() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => {
        if (prevIndex === options.length - 1) {
          return 0; // Instantly return to the start
        }
        return prevIndex + 1;
      });
    }, 1000); // Change text every 1 seconds (0.5 second display + 0.5 second transition)

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.clientHeight / options.length;
      containerRef.current.style.transform = `translateY(-${
        currentIndex * height
      }px)`;
    }
  }, [currentIndex]);

  return (
    <h1 className=" text-code-xl sm:text-code-2xl md:text-code-3xl lg:text-code-4xl xl:text-code-5xl 2xl:text-code-6xl text-2">
      <div>Your product could have</div>
      <div className="relative h-24 sm:h-16 lg:h-24 2xl:h-32 mt-2 lg:mt-4 overflow-hidden">
        <div ref={containerRef}>
          {options.map((option, index) => (
            <div
              key={index}
              className={`${option.className} flex flex-col sm:flex-row w-auto items-center justify-center text-left h-24 sm:h-16 lg:h-24 2xl:h-32 gap-2 sm:gap-6 lg:gap-8 2xl:gap-12 font-pixel uppercase`}>
              <Icon
                icon={option.icon}
                className="w-12 h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20 2xl:w-24 2xl:h-24"
              />
              <span className="whitespace-nowrap">{option.text}</span>
            </div>
          ))}
        </div>
      </div>
      <div>
        by{' '}
        <span className="text-1">
          {new Date(Date.now() + 3 * 60 * 1000).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}{' '}
        </span>
        today
      </div>
    </h1>
  );
}
